<template>
  <div class="datosContactos">
    <div class="contacto">
      <Titulo Titulo="Datos de contacto" />
    </div>
    <div class="info">
      <Subtitulo3 subTitulo3="¿A quién le mandamos tus boletos?" />
    </div>
    <div>
      <input v-model="nombre" type="nombre" placeholder="Nombre(s)" />
    </div>
    <div>
      <input v-model="apellido" type="apellido" placeholder="Apellido" />
    </div>
    <div>
      <input v-model="telefono" type="Telefono" placeholder="Teléfono" />
    </div>
    <div>
      <input v-model="email" type="email" placeholder="E-mail" />
    </div>
    <div class="alert alert-danger" role="alert" v-if="error">
      {{ error_msg }}
    </div>
    <button @click="saveValue()">
      <App-button buttonText="Continuar" />
    </button>
  </div>
</template>

<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@media screen and (max-width: 480px) {
  .contacto {
    font-size: 18px;
    margin-top: 20px;
    margin-left: -26%;
  }
  .info {
    font-size: 14px;
    margin-top: -8%;
    margin-left: -20%;
  }
  input[type="Telefono"] {
    margin-top: 20px;
    margin-right: 19%;
    margin-left: 5%;
    text-indent: 30px;
    box-shadow: 1px 1px 1px;
    height: 45px;
    width: 340px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  input[placeholder] {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 600;
  }
  input[type="email"] {
    margin-top: 20px;
    margin-right: 20%;
    text-indent: 30px;
    margin-left: 5%;
    box-shadow: 1px 1px 1px;
    height: 45px;
    width: 340px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  input[type="nombre"] {
    margin-top: 0px;
    margin-right: 19%;
    margin-left: 5%;
    text-indent: 30px;
    box-shadow: 1px 1px 1px;
    height: 45px;
    width: 340px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  input[type="apellido"] {
    margin-top: 20px;
    margin-right: 20%;
    text-indent: 30px;
    margin-left: 5%;
    box-shadow: 1px 1px 1px;
    height: 45px;
    width: 340px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  .alert {
    margin-top: 3%;
    padding: -10px;
    margin-bottom: 0px;
    border: 5px solid transparent;
    border-radius: 10px;
  }
  .alert-danger {
    margin-left: 5%;
    margin-right: 5%;
    color: #ffffff;
    background-color: #fb8c00;
  }
}
@media screen and (min-width: 481px) and (max-width: 767px) {
  .contacto {
    font-size: 18px;
    margin-top: 20px;
    margin-left: -8%;
  }
  .info {
    font-size: 18px;
    margin-top: -4%;
    margin-left: -0%;
  }
  input[type="Telefono"] {
    margin-top: 20px;
    margin-right: 19%;
    margin-left: 20%;
    text-indent: 30px;
    box-shadow: 1px 1px 1px;
    height: 45px;
    width: 340px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  input[placeholder] {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 600;
  }
  input[type="email"] {
    margin-top: 20px;
    margin-right: 20%;
    text-indent: 30px;
    margin-left: 20%;
    box-shadow: 1px 1px 1px;
    height: 45px;
    width: 340px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  input[type="nombre"] {
    margin-top: 20px;
    margin-right: 20%;
    text-indent: 30px;
    margin-left: 20%;
    box-shadow: 1px 1px 1px;
    height: 45px;
    width: 340px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  input[type="apellido"] {
    margin-top: 20px;
    margin-right: 20%;
    text-indent: 30px;
    margin-left: 20%;
    box-shadow: 1px 1px 1px;
    height: 45px;
    width: 340px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  .alert {
    margin-top: 3%;
    padding: 0px;
    margin-bottom: 0px;
    border: 5px solid transparent;
    border-radius: 10px;
  }
  .alert-danger {
    margin-left: 10%;
    margin-right: 10%;
    color: #ffffff;
    background-color: #fb8c00;
  }
}
@media screen and (min-width: 768px) and (max-width: 1033px) {
  .contacto {
    font-size: 20px;
    margin-top: 1%;
    margin-left: -1%;
  }
  .info {
    font-size: 18px;
    margin-top: -3%;
    margin-left: 2%;
  }
  input[type="Telefono"] {
    margin-top: 20px;
    margin-right: 2%;
    margin-left: 8%;
    text-indent: 30px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  input[placeholder] {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 600;
  }
  input[type="email"] {
    margin-top: 20px;
    margin-right: -9%;
    text-indent: 30px;
    margin-left: -3%;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  input[type="nombre"] {
    margin-top: 0px;
    margin-right: 2%;
    margin-left: 8%;
    text-indent: 30px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  input[type="apellido"] {
    margin-top: 20px;
    margin-right: 2%;
    margin-left: 8%;
    text-indent: 30px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }

  .alert {
    margin-top: 3%;
    padding: 0px;
    margin-bottom: 0px;
    border: 5px solid transparent;
    border-radius: 10px;
  }
  .alert-danger {
    margin-left: 10%;
    margin-right: 10%;
    color: #ffffff;
    background-color: #fb8c00;
  }
}
@media screen and (min-width: 1034px) and (max-width: 1280px) {
  .contacto {
    font-size: 20px;
    margin-top: 0%;
    margin-left: -12%;
  }
  .info {
    font-size: 18px;
    margin-top: -1%;
    margin-left: -6%;
  }
  input[type="Telefono"] {
    margin-top: 20px;
    margin-right: 12%;
    margin-left: 8%;
    text-indent: 30px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  input[placeholder] {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 600;
  }
  input[type="email"] {
    margin-top: 20px;
    margin-right: 1%;
    text-indent: 30px;
    margin-left: -3%;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  input[type="nombre"] {
    margin-top: 20px;
    margin-right: 1%;
    text-indent: 30px;
    margin-left: -3%;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  input[type="apellido"] {
    margin-top: 20px;
    margin-right: 1%;
    text-indent: 30px;
    margin-left: -3%;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  .alert {
    margin-top: 2%;
    padding: 5px;
    margin-bottom: 1px;
    border: 5px solid transparent;
    border-radius: 10px;
  }
  .alert-danger {
    margin-left: 15%;
    margin-right: 15%;
    color: #ffffff;
    background-color: #fb8c00;
  }
}
@media screen and (min-width: 1281px) and (max-width: 1365px){
  .contacto {
    font-size: 20px;
    margin-top: 0%;
    margin-left: -12%;
  }
  .info {
    font-size: 18px;
    margin-top: -1%;
    margin-left: -6%;
  }
  input[type="Telefono"] {
    margin-top: 20px;
    margin-right: 12%;
    margin-left: 8%;
    text-indent: 30px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  input[placeholder] {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 600;
  }
  input[type="email"] {
    margin-top: 20px;
    margin-right: 1%;
    text-indent: 30px;
    margin-left: -3%;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  input[type="nombre"] {
    margin-top: 20px;
    margin-right: 1%;
    text-indent: 30px;
    margin-left: -3%;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  input[type="apellido"] {
    margin-top: 20px;
    margin-right: 1%;
    text-indent: 30px;
    margin-left: -3%;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  .alert {
    margin-top: 2%;
    padding: 5px;
    margin-bottom: 1px;
    border: 5px solid transparent;
    border-radius: 10px;
  }
  .alert-danger {
    margin-left: 15%;
    margin-right: 15%;
    color: #ffffff;
    background-color: #fb8c00;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1439px) {
  .contacto {
    font-size: 20px;
    margin-top: 0%;
    margin-left: -2%;
  }
  .info {
    font-size: 18px;
    margin-top: -1%;
    margin-left: 0%;
  }
  input[type="Telefono"] {
    margin-top: 20px;
    margin-right: 1%;
    margin-left: 0%;
    text-indent: 30px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  input[placeholder] {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 600;
  }
  input[type="email"] {
    margin-top: 20px;
    margin-right: 1%;
    text-indent: 30px;
    margin-left: -0%;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  input[type="nombre"] {
    margin-top: 20px;
    margin-right: 1%;
    text-indent: 30px;
    margin-left: -0%;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  input[type="apellido"] {
    margin-top: 20px;
    margin-right: 1%;
    text-indent: 30px;
    margin-left: -0%;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  .alert {
    margin-top: 2%;
    padding: 5px;
    margin-bottom: 1px;
    border: 5px solid transparent;
    border-radius: 10px;
  }
  .alert-danger {
    margin-left: 15%;
    margin-right: 15%;
    color: #ffffff;
    background-color: #fb8c00;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1919px) {
  .contacto {
    font-size: 20px;
    margin-top: 0%;
    margin-left: -2%;
  }
  .info {
    font-size: 18px;
    margin-top: -1%;
    margin-left: 0%;
  }
  input[type="Telefono"] {
    margin-top: 20px;
    margin-right: 1%;
    margin-left: 0%;
    text-indent: 30px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  input[placeholder] {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 600;
  }
  input[type="email"] {
    margin-top: 20px;
    margin-right: 1%;
    text-indent: 30px;
    margin-left: -0%;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  input[type="nombre"] {
    margin-top: 20px;
    margin-right: 1%;
    text-indent: 30px;
    margin-left: -0%;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  input[type="apellido"] {
    margin-top: 20px;
    margin-right: 1%;
    text-indent: 30px;
    margin-left: -0%;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  .alert {
    margin-top: 2%;
    padding: 5px;
    margin-bottom: 1px;
    border: 5px solid transparent;
    border-radius: 10px;
  }
  .alert-danger {
    margin-left: 15%;
    margin-right: 15%;
    color: #ffffff;
    background-color: #fb8c00;
  }
}
@media screen and (min-width: 1920px) {
  .contacto {
    font-size: 20px;
    margin-top: 0%;
    margin-left: -2%;
  }
  .info {
    font-size: 18px;
    margin-top: -1%;
    margin-left: 0%;
  }
  input[type="Telefono"] {
    margin-top: 20px;
    margin-right: 1%;
    margin-left: 0%;
    text-indent: 30px;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  input[placeholder] {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 600;
  }
  input[type="email"] {
    margin-top: 20px;
    margin-right: 1%;
    text-indent: 30px;
    margin-left: -0%;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  input[type="nombre"] {
    margin-top: 20px;
    margin-right: 1%;
    text-indent: 30px;
    margin-left: -0%;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  input[type="apellido"] {
    margin-top: 20px;
    margin-right: 1%;
    text-indent: 30px;
    margin-left: -0%;
    box-shadow: 1px 1px 1px;
    height: 50px;
    width: 380px;
    background-color: rgba(248, 250, 252, 255);
    border: 2px solid rgba(248, 250, 252, 255);
    border-radius: 10px;
  }
  .alert {
    margin-top: 2%;
    padding: 5px;
    margin-bottom: 1px;
    border: 5px solid transparent;
    border-radius: 10px;
  }
  .alert-danger {
    margin-left: 15%;
    margin-right: 15%;
    color: #ffffff;
    background-color: #fb8c00;
  }
}

</style>

<script>
import { router } from "@/router";
import CryptoJS from "crypto-js";
import Stepper5 from "@/components/Stepper/Stepper5.vue";
import Titulo from "@/components/title.vue";
import Subtitulo3 from "@/components/SubTitle3.vue";
import InfoPasajero from "@/components/InfoPasajero.vue";
import AppButton from "@/components/ButtonPrimary.vue";
import Back from "@/components/Back.vue";
import DatosContacto from "@/components/DatosContactos.vue";
export default {
  name: "DatosContacto",
  props: {
    boletos_ida: {required:false},
    boletos_vuelta: {required:false}
  },
  components: {
    Stepper5,
    Titulo,
    Subtitulo3,
    Back,
    AppButton,
    InfoPasajero,
    DatosContacto,
  },
  data: function () {
    return {
      data: null,
      telefono: "",
      email: "",
      nombre: "",
      apellido: "",
      error: false,
      error_msg: "",
    };
  },
  mounted() {
    this.boletos = JSON.parse(this.boletos_ida);
    const email = localStorage.getItem("email");
    if(email !== undefined || email !== '' || email !== null){
      this.email = localStorage.getItem("email");
      this.nombre = localStorage.getItem("nombre");
      this.apellido = localStorage.getItem("apellido");
      this.telefono = localStorage.getItem("telefono");
    }
  },
  created() {
    let storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      this.emailValue = storedEmail;
    }
  },
  methods: {
    decryptData(encryptedData) {
      const secretKey = "my-secret-key"; // clave secreta para el cifrado
      const decryptedData = CryptoJS.AES.decrypt(encryptedData, secretKey);
      return decryptedData.toString(CryptoJS.enc.Utf8);
    },
    encryptData(data) {
      const secretKey = "my-secret-key"; // clave secreta para el cifrado
      const encryptedData = CryptoJS.AES.encrypt(data, secretKey);
      return encryptedData.toString();
    },

    saveValue() {
      const value = localStorage.getItem("value");
      const boletos = JSON.parse(this.boletos_ida);

      for(let i = 0; i < boletos.length; i++){
        if(boletos[i].nombre_pasajero === undefined || boletos[i].nombre_pasajero === ""){
          this.$notify({
            group: "apolo",
            type: "error",
            title: "Notificación Apolo Platinum",
            text: "Todos los nombres de los pasajeros es obligatorio.",
            duration: -1
          });
          return;
        }
        if(boletos[i].edad === undefined || boletos[i].edad === ""){
          this.$notify({
            group: "apolo",
            type: "error",
            title: "Notificación Apolo Platinum",
            text: "Todos las edades de los pasajeros es obligatoria.",
            duration: -1
          });
          return;
        }
      }
      if (value == 0){
        const boletos_vuelta = JSON.parse(this.boletos_vuelta);
        for(let i = 0; i < boletos_vuelta.length; i++){
          if(boletos_vuelta[i].nombre_pasajero === undefined || boletos_vuelta[i].nombre_pasajero === ""){
            this.$notify({
              group: "apolo",
              type: "error",
              title: "Notificación Apolo Platinum",
              text: "Todos los nombres de los pasajeros es obligatorio.",
              duration: -1
            });
            return;
          }
          if(boletos_vuelta[i].edad === undefined || boletos_vuelta[i].edad === ""){
            this.$notify({
              group: "apolo",
              type: "error",
              title: "Notificación Apolo Platinum",
              text: "Todos las edades de los pasajeros es obligatoria.",
              duration: -1
            });
            return;
          }
        }
      }
      
      if (
        this.telefono === "" ||
        this.email === "" ||
        this.nombre === "" ||
        this.apellido === "" || this.telefono === null ||
        this.email === null ||
        this.nombre === null ||
        this.apellido === null
      ) {
        //this.error = true;
        //this.error_msg = "Todos los campos de datos de contacto son obligatorios.";
        this.$notify({
          group: "apolo",
          type: "error",
          title: "Notificación Apolo Platinum",
          text: "Todos los campos de datos de contacto son obligatorios.",
          duration: -1
        });
        return;
      } else {
        if(this.telefono.length != 10){
          //this.error = true;
          //this.error_msg = "El teléfono debe contener 10 números.";
          this.$notify({
            group: "apolo",
            type: "error",
            title: "Notificación Apolo Platinum",
            text: "El teléfono debe contener 10 números.",
            duration: -1
          });
          return;
        }
        if(!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email))){
          //this.error = true;
          //this.error_msg = "El correo es inválido.";
          this.$notify({
            group: "apolo",
            type: "error",
            title: "Notificación Apolo Platinum",
            text: "El correo es inválido.",
            duration: -1
          });
          return;
        }
        //Código para enviar el formulario
        localStorage.setItem("email", this.email);
        localStorage.setItem("nombre", this.nombre);
        localStorage.setItem("apellido", this.apellido);
        localStorage.setItem("telefono", this.telefono);

        if (value == 0) {
          //ida
          this.boletos = JSON.parse(this.boletos_ida);
          const totalDescuentoValor = this.boletos.reduce(
            (acumulador, boleto) => {
              if (boleto.descuento && boleto.descuento.valor) {
                return acumulador + parseFloat(boleto.descuento.valor);
              }
              return acumulador;
            },
            0
          );
          const totalValor = this.boletos.reduce(
            (acumulador, boleto) => {
              if ( boleto.precio_lista) {
                return acumulador + parseFloat(boleto.precio_lista);
              }
              return acumulador;
            },
            0
          );
          this.boletosVuelta = JSON.parse(this.boletos_vuelta);
          const totalDescuentoValorVuelta = this.boletosVuelta.reduce(
            (acumulador, boletoVuelta) => {
              if (boletoVuelta.descuento && boletoVuelta.descuento.valor) {
                return acumulador + parseFloat(boletoVuelta.descuento.valor);
              }
              return acumulador;
            },
            0
          );
          const totalValorVuelta = this.boletosVuelta.reduce(
            (acumulador, boletoVuelta2) => {
              if ( boletoVuelta2.precio_lista) {
                return acumulador + parseFloat(boletoVuelta2.precio_lista);
              }
              return acumulador;
            },
            0
          );
          //Total de descuento 
          const TotalDescuento = parseFloat(totalDescuentoValor) + parseFloat(totalDescuentoValorVuelta);
          //Total de monto
          const Total = parseFloat(totalValor) + parseFloat(totalValorVuelta);
          const valor = Total - TotalDescuento;
          const encryptedSuma = this.encryptData(valor.toString());
          localStorage.setItem('key', encryptedSuma);
          //El codigo esta en redondo
          //Obten el Total del los boletos

          this.$router.push("/paso6");
          this.totalAplicado = true;
        } else {
          this.boletos = JSON.parse(this.boletos_ida);
          const totalDescuentoValor = this.boletos.reduce(
            (acumulador, boleto) => {
              if (boleto.descuento && boleto.descuento.valor) {
                return acumulador + parseFloat(boleto.descuento.valor);
              }
              return acumulador;
            },
            0
          );
          const totalValor = this.boletos.reduce(
            (acumulador, boleto) => {
              if ( boleto.precio_lista) {
                return acumulador + parseFloat(boleto.precio_lista);
              }
              return acumulador;
            },
            0
          );
          const valor = totalValor - parseFloat(totalDescuentoValor);
            const encryptedSuma = this.encryptData(valor.toString());
            localStorage.setItem("key", encryptedSuma);
            this.$router.push("/paso6");
          
        }
      }
    },
  },
};
</script>